import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Icon,
} from "@chakra-ui/react";
import { useSession, signOut } from "next-auth/react";
import { CreditCard, LogOut, User } from "react-feather";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import getConfig from "next/config";

const MenuComponent = (): React.ReactElement => {
  const { t } = useTranslation();
  const router = useRouter();
  const { status, data } = useSession();
  const { publicRuntimeConfig } = getConfig();

  if (status === "unauthenticated")
    return (
      <Avatar
        ml={3}
        bg="red.500"
        showBorder
        icon={<Icon as={User} color="white" w="27px" h="27px" />}
        onClick={() => router.push("/signin")}
        cursor="pointer"
      />
    );

  return (
    <Menu isLazy>
      <MenuButton>
        <Avatar
          mx={3}
          bg="red.500"
          showBorder
          src={
            data?.user?.profile?.picture
              ? `${publicRuntimeConfig.apiUrl}/uploads/${data?.user?.profile?.picture}`
              : ""
          }
          icon={<Icon as={User} color="white" w="27px" h="27px" />}
        />
      </MenuButton>
      <MenuList>
        <>
          {/*<MenuItem
            icon={
              <Icon
                as={StarIcon}
                color="yellow.500"
                w="20px"
                h="20px"
                verticalAlign="-4px"
              />
            }
            onClick={() => router.push("/favorites")}
          >
            {t("favoriteGarages")}
          </MenuItem>
          <MenuItem
            icon={
              <Icon
                as={User}
                color="blue.500"
                w="20px"
                h="20px"
                verticalAlign="-4px"
              />
            }
            onClick={() => router.push("/profile")}
          >
            {t("profile")}
          </MenuItem>*/}
          <MenuItem
            icon={
              <Icon
                as={CreditCard}
                color="blue.500"
                w="20px"
                h="20px"
                verticalAlign="-4px"
              />
            }
            onClick={() => router.push("/subscription")}
          >
            {t("garage.subscription")}
          </MenuItem>
          <MenuItem
            icon={
              <Icon
                as={LogOut}
                color="blue.500"
                w="20px"
                h="20px"
                verticalAlign="-4px"
              />
            }
            onClick={() => signOut()}
          >
            {t("logOut")}
          </MenuItem>
        </>
      </MenuList>
    </Menu>
  );
};

export default MenuComponent;
