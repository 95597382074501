import { Language, LanguageShort } from "@/types/language";

export const languages = [Language.POLISH, Language.ENGLISH];

export const flags: { [key in Language]: string } = {
  [Language.POLISH]: "pl.png",
  [Language.ENGLISH]: "en.png",
};

export const getFlag = (language: string): string => flags[language];

export const languagesShort = [LanguageShort.PL, LanguageShort.EN];

export const flagsShort: { [key in LanguageShort]: string } = {
  [LanguageShort.PL]: "pl.png",
  [LanguageShort.EN]: "en.png",
};

export const getFlagShort = (languagesShort: string): string =>
  flagsShort[languagesShort];
