import React, { useMemo } from "react";
import {
  Box,
  Flex,
  Heading,
  Image,
  Link,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Icon,
} from "@chakra-ui/react";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { useTranslation as useTranslationi18n } from "react-i18next";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { CheckCircle, FileText, Mail } from "react-feather";
import { useCookies } from "react-cookie";

import ButtonAnimated from "@/components/common/ButtonAnimated";
import Menu from "@/components/Layout/Menu";
import StickyHeader from "./StickyHeader";
import checkIfFullFridge from "@/utils/fullFridge";
import { LanguageShort } from "@/types/language";
import { languagesShort, getFlagShort } from "@/utils/language";

export default function Layout({
  hideFunctionality = false,
  hideFooter = false,
  children,
}) {
  const { pageRef, isSticky } = StickyHeader();
  const { publicRuntimeConfig } = getConfig();
  const { t } = useTranslation();
  const { i18n } = useTranslationi18n();
  const router = useRouter();
  const { data: session, status } = useSession();
  const [cookie, setCookie] = useCookies(["NEXT_LOCALE"]);
  const { pathname, locale, asPath } = router;
  const isActive = (path: string) =>
    pathname === path || (pathname.startsWith(path) && !path.endsWith("/"));

  const languages = useMemo(
    () =>
      languagesShort.reduce(
        (acc, curr) => (
          (acc[curr] = {
            icon: getFlagShort(curr),
          }),
          acc
        ),
        {}
      ),
    []
  );
  const currentLanguage = languages[locale];

  const switchLanguage = (locale) => async () => {
    if (cookie.NEXT_LOCALE !== locale)
      setCookie("NEXT_LOCALE", locale, { path: "/" });

    router.push(asPath, asPath, { locale });
  };

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        bg={checkIfFullFridge() ? "green.500" : "red.500"}
        pl={{ base: "15px", sm: "50px", xl: "100px" }}
        pr={{ base: "15px", sm: "50px", xl: "100px" }}
        pt="10px"
        pb="10px"
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={1001}
        minH={{ base: "66px", sm: "78px" }}
      >
        <Box
          as="a"
          href="#"
          bg="white"
          borderRadius="10px"
          py="8px"
          px="10px"
          onClick={() => router.push("/")}
        >
          <Image
            src={
              checkIfFullFridge()
                ? `${publicRuntimeConfig.basePath}/logo-food.svg`
                : `${publicRuntimeConfig.basePath}/logo.svg`
            }
            alt=""
            w={{ base: "140px", sm: "196px" }}
          />
        </Box>
        <Flex align="center">
          {/*!hideFunctionality && (
            <>
              <Stack direction="row" mr="50px">
                <Box
                  as="a"
                  href="#"
                  role="group"
                  color="white"
                  textTransform="uppercase"
                  letterSpacing="1px"
                  px="15px"
                  onClick={() => router.push("/")}
                >
                  Szukam
                  <Box
                    h="3px"
                    borderRadius="3px"
                    mx="auto"
                    mt="3px"
                    bg="white"
                    opacity={isActive("/") || isActive("/p") ? 0.5 : 0}
                    w={isActive("/") || isActive("/p") ? "100%" : "1px"}
                    transition="all .25s ease-in-out"
                    _groupHover={{ opacity: 0.5, w: "100%" }}
                  ></Box>
                </Box>
                <Box
                  as="a"
                  href="#"
                  role="group"
                  color="white"
                  textTransform="uppercase"
                  letterSpacing="1px"
                  px="15px"
                  onClick={() => router.push("/add-product")}
                >
                  Sprzedaję
                  <Box
                    h="3px"
                    borderRadius="3px"
                    mx="auto"
                    mt="5px"
                    bg="white"
                    opacity={isActive("/add-product") ? 0.5 : 0}
                    w={isActive("/add-product") ? "100%" : "1px"}
                    transition="all .25s ease-in-out"
                    _groupHover={{ opacity: 0.5, w: "100%" }}
                  ></Box>
                </Box>
                <Box
                  as="a"
                  href="#"
                  role="group"
                  color="white"
                  textTransform="uppercase"
                  letterSpacing="1px"
                  px="15px"
                  onClick={() => router.push("/chat")}
                >
                  Czat
                  <Box
                    h="3px"
                    borderRadius="3px"
                    mx="auto"
                    mt="5px"
                    bg="white"
                    opacity={isActive("/chat") ? 0.5 : 0}
                    w={isActive("/chat") ? "100%" : "1px"}
                    transition="all .25s ease-in-out"
                    _groupHover={{ opacity: 0.5, w: "100%" }}
                  ></Box>
                </Box>
              </Stack>
              <ButtonAnimated
                text={
                  status === "authenticated" && session?.user?.garages[0]?.id
                    ? t("myGarage")
                    : t("rentGarage")
                }
                bg="red.600"
                _hover={{ bg: "red.700" }}
                onClick={() => router.push(`/garage`)}
              />
              
            </>
          )*/}
          {!checkIfFullFridge() && <Menu />}
          <ChakraMenu>
            <MenuButton as="a">
              <Image
                src={`${publicRuntimeConfig.basePath}/${currentLanguage?.icon}`}
                alt=""
                w="25px"
                d="block"
                m="0.4rem 0.8rem"
              />
            </MenuButton>
            <MenuList minW={0}>
              {Object.keys(languages).map((key) => (
                <MenuItem
                  key={key}
                  onClick={switchLanguage(key)}
                  isDisabled={key === locale}
                >
                  <Image
                    src={`${publicRuntimeConfig.basePath}/${languages[key]?.icon}`}
                    alt=""
                    w="25px"
                  />
                </MenuItem>
              ))}
            </MenuList>
          </ChakraMenu>
        </Flex>
      </Flex>
      <Box ref={pageRef}>{children}</Box>
      {!hideFooter && (
        <Box p={{ base: "30px 15px", sm: "50px", xl: "100px" }} bg="gray.200">
          <SimpleGrid
            columns={
              !hideFunctionality && 0
                ? { base: 1, sm: 2, md: 3, xl: 4 }
                : { base: 1, sm: 2 }
            }
            spacing={{ base: "30px", sm: "40px" }}
          >
            {/*!hideFunctionality && (
              <Box>
                <Heading
                  size="md"
                  variant="gray"
                  mt={0}
                  fontSize={{ base: "18px", sm: "20px" }}
                >
                  Kategorie
                </Heading>
                <List spacing="5px">
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Dom i ogród</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Moda</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Dziecko</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Sport</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Kultura i rozrywka</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Elektronika</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Sztuka</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Uroda</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Motoryzacja</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Nieruchomości</Link>
                  </ListItem>
                  <ListItem>
                    <ListIcon
                      as={CheckCircle}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link>Jedzenie</Link>
                  </ListItem>
                </List>
              </Box>
            )*/}
            <Box>
              <Heading
                size="md"
                variant="gray"
                mt={0}
                fontSize={{ base: "18px", sm: "20px" }}
              >
                {t("home.information")}
              </Heading>
              <List spacing="5px">
                <ListItem>
                  <ListIcon
                    as={FileText}
                    w="15px"
                    h="15px"
                    color="gray.500"
                    verticalAlign="-2px"
                  />
                  <Link
                    href={`${publicRuntimeConfig.basePath}/`}
                    // href={`${publicRuntimeConfig.basePath}/aplikacja-mobilna`}
                  >
                    {t("home.mobileApp")}
                  </Link>
                </ListItem>
                {!checkIfFullFridge() && (
                  <ListItem>
                    <ListIcon
                      as={FileText}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link
                      href={`${publicRuntimeConfig.basePath}/terms-and-conditions`}
                    >
                      {t("regulations")}
                    </Link>
                  </ListItem>
                )}
                {!checkIfFullFridge() && (
                  <ListItem>
                    <ListIcon
                      as={FileText}
                      w="15px"
                      h="15px"
                      color="gray.500"
                      verticalAlign="-2px"
                    />
                    <Link
                      href={`${publicRuntimeConfig.basePath}/privacy-policy`}
                    >
                      {t("privacyPolicy")}
                    </Link>
                  </ListItem>
                )}
                <ListItem>
                  <ListIcon
                    as={FileText}
                    w="15px"
                    h="15px"
                    color="gray.500"
                    verticalAlign="-2px"
                  />
                  <Link
                    href={
                      checkIfFullFridge()
                        ? `${publicRuntimeConfig.basePath}/terms-and-conditions`
                        : "https://fullfridge.app/terms-and-conditions"
                    }
                  >
                    {!checkIfFullFridge()
                      ? t("regulationsFullFridge")
                      : t("regulations")}
                  </Link>
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={FileText}
                    w="15px"
                    h="15px"
                    color="gray.500"
                    verticalAlign="-2px"
                  />
                  <Link
                    href={
                      checkIfFullFridge()
                        ? `${publicRuntimeConfig.basePath}/privacy-policy`
                        : "https://fullfridge.app/privacy-policy"
                    }
                  >
                    {!checkIfFullFridge()
                      ? t("privacyPolicyFullFridge")
                      : t("privacyPolicy")}
                  </Link>
                </ListItem>
              </List>
            </Box>
            <Box>
              <Box
                as="a"
                mb="20px"
                d="block"
                href="https://copernicusapps.com/"
                target="_blank"
              >
                <Image
                  src={`${publicRuntimeConfig.basePath}/logo-copernicus.svg`}
                  alt=""
                  w="130px"
                />
              </Box>
              <Stack
                direction="row"
                spacing="10px"
                mb="20px"
                alignItems="center"
              >
                <Icon as={Mail} w="25px" h="25px" color="gray.500" />
                <Link
                  fontSize="18px"
                  href={
                    !checkIfFullFridge()
                      ? "mailto:kontakt@garazowewyprzedaze.pl"
                      : "mailto:office@fullfridge.app"
                  }
                >
                  {!checkIfFullFridge()
                    ? "kontakt@garazowewyprzedaze.pl"
                    : "office@fullfridge.app"}
                </Link>
              </Stack>
              <Stack direction="row" spacing="10px">
                <a
                  href={
                    !checkIfFullFridge()
                      ? "https://garazowewyprzedaze.pl/download/android"
                      : "https://fullfridge.app/download/android"
                  }
                  rel="noopener"
                >
                  <Image
                    src={
                      i18n?.language == "pl"
                        ? `${publicRuntimeConfig.basePath}/google-play.png`
                        : `${publicRuntimeConfig.basePath}/google-play-en.png`
                    }
                    alt=""
                    h="35px"
                  />
                </a>
                <a
                  href={
                    !checkIfFullFridge()
                      ? "https://garazowewyprzedaze.pl/download/ios"
                      : "https://fullfridge.app/download/ios"
                  }
                  rel="noopener"
                >
                  <Image
                    src={
                      i18n?.language == "pl"
                        ? `${publicRuntimeConfig.basePath}/app-store.png`
                        : `${publicRuntimeConfig.basePath}/app-store-en.png`
                    }
                    alt=""
                    h="35px"
                  />
                </a>
              </Stack>
            </Box>
          </SimpleGrid>
          <Text
            fontSize="12px"
            color="gray.600"
            mt={{ base: "30px", md: "50px" }}
          >
            {t("copernicusInfo")}
          </Text>
        </Box>
      )}
    </>
  );
}
